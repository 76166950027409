var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
            name: 'DashboardHome',
          }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'SystemSettingsHome',
            }}},[_vm._v("全系統管理")]),_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'OrganizationList',
            }}},[_vm._v("組織管理")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("通路管理")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('h4',{staticClass:"col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold"},[_vm._v(_vm._s(_vm.organizationName)+"通路管理")]),_c('div',{staticClass:"col-12 col-xl-6 d-flex flex-column flex-xl-row align-items-start align-items-xl-center"},[(_vm.checkPermission([_vm.consts.MERCHANT_MODIFY]))?_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0 mr-2",attrs:{"variant":"primary"},on:{"click":_vm.handleCreate}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("新增通路 ")]):_vm._e(),_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"搜尋通路名稱"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handlePageResetWithoutReload.apply(null, arguments)}},model:{value:(_vm.query.keyword),callback:function ($$v) {_vm.$set(_vm.query, "keyword", $$v)},expression:"query.keyword"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":_vm.handlePageResetWithoutReload}},[_c('i',{staticClass:"fa fa-search"})])],1)],1)],1)]),_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.merchants,"fields":_vm.fields,"busy":_vm.isLoading},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(actions)",fn:function(data){return [(_vm.checkPermission([_vm.consts.MERCHANT_MODIFY]))?_c('b-button',{attrs:{"variant":"inverse-warning","to":{
            name: 'OrgMerchantEdit',
            params: {
              orgId: _vm.orgId,
              merchantId: data.item.id,
            },
          }}},[_vm._v("編輯")]):_vm._e(),(_vm.checkPermission([_vm.consts.MERCHANT_DELETE]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"danger","disabled":data.item.is_enabled === 1},on:{"click":function($event){return _vm.handleDelete(data.item.id, data.item.name, data.item.type)}}},[_vm._v("刪除")]):_vm._e()]}}])}),_c('CustomPagination',{attrs:{"currentPage":_vm.query.page,"totalRows":_vm.total,"perPage":_vm.query.per_page},on:{"page-change":_vm.handlePageChange,"per-page-change":_vm.handlePerPageChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }